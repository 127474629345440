.header-main {
  border-bottom: 1px solid #2d2d2d;
  position: sticky;
  top: 0;
  background: #000;
  z-index: 99;
}

.header-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
}

.header-wrap .brand-logo img {
  max-width: 100%;
}

.header-wrap .download-btn {
  background-image: linear-gradient(#95f500, #95f500),
    linear-gradient(73.47deg,
      #ffffff 15.83%,
      #000000 30.01%,
      #000000 63.78%,
      #ffffff 85.19%);
  background-origin: border-box;
  background-clip: content-box, border-box;
  border: solid 1px transparent;
  background-color: #95f500;
  font-family: AileronSemiBold;
  padding: 0;
  border-radius: 8px;
  cursor: pointer;
}

.header-wrap .download-btn>span {
  font-size: 20px;
  line-height: 24px;
  gap: 16px;
  padding: 12px 16px;
  display: flex;
  align-items: center;
}

@media (max-width: 1920px) {
  .header-wrap .brand-logo {
    max-width: 280px;
  }
}

@media (max-width: 991px) {
  .header-wrap .brand-logo {
    max-width: 200px;
  }

  .header-wrap .download-btn>span {
    font-size: 16px;
    line-height: 20px;
    gap: 12px;
    padding: 8px 12px;
  }
}

@media (max-width: 575px) {
  .header-wrap .brand-logo {
    max-width: 150px;
  }

  .header-wrap .download-btn>span {
    font-size: 12px;
    line-height: 14px;
    padding: 6px 12px;
  }

  .header-wrap .download-btn svg {
    width: 16px;
    height: 16px;
  }
}

@media (max-width: 425px) {
  .header-wrap .brand-logo {
    max-width: 120px;
  }
}