.faqs-sec {
  padding: 150px 0;
}

.faqs-sec h2 {
  font-family: "Unbounded", sans-serif;
  font-size: 60px;
  font-weight: 600;
  line-height: 74.4px;
  text-align: center;
  margin: 0 0 54px 0;
}

.wrapper {
  /* width: 600px;
  margin: 0 auto; */
}

.accordion-wrapper {
  border-bottom: 1px solid #ffffff1a;
}

.accordion-item {
  overflow: hidden;
  transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
  height: auto;
  max-height: 9999px;
}

.accordion-item.collapsed {
  max-height: 0;
  transition: max-height 0.35s cubic-bezier(0, 1, 0, 1);
}

.accordion-title {
  font-weight: 600;
  cursor: pointer;
  color: #95F500;
  padding: 16px 0;
  border-radius: 1.5em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: AileronRegular;
  font-size: 30px;
  font-weight: 400;
  line-height: 36px;
  text-align: left;


  &::after {
    content: "+";
    color: #fff;
    font-size: 42px;
  }

  &.open {
    &::after {
      content: "-";
      color: #fff;
    }
  }
}

.accordion-content {
  padding: 0 0 30px 0;
  font-family: AileronRegular;
  font-size: 22px;
  font-weight: 400;
  line-height: 26.4px;
  letter-spacing: 0.02em;
  text-align: left;
}

@media (max-width: 991px) {
  .accordion-title {
    font-size: 24px;
    line-height: 28px;
    padding: 12px 0;
  }

  .accordion-content {
    font-size: 18px;
    line-height: 22px;
    padding: 0 0 20px 0;
  }
}

@media (max-width: 768px) {
  .faqs-sec {
    padding: 60px 0;
  }

  .faqs-sec h2 {
    font-size: 32px;
    line-height: 40px;
    margin: 0 0 42px 0;
  }

  .accordion-title {
    font-size: 20px;
    line-height: 24px;
  }

  .accordion-content {
    font-size: 16px;
    line-height: 20px;
  }
}

@media (max-width: 575px) {
  .accordion-title {
    font-size: 16px;
    line-height: 20px;
  }

  .accordion-content {
    font-size: 12px;
    line-height: 14px;
  }

}

@media (max-width: 425px) {
  .faqs-sec h2 {
    font-size: 24px;
    line-height: 34px;
    margin: 0 0 24px 0;
  }
}