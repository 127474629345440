.blog-card .card-wrapper {
  gap: 40px;
  display: flex;
  align-items: center;
  position: relative;
}

.blog-card .card-wrapper.left-text::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 60%;
  height: 12px;
  background-image: url("../../Assets/images/right-line.png");
  /* background-size: cover; */
  background-position: left;
}

.blog-card .card-wrapper.right-text::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  width: 60%;
  height: 12px;
  /* background-size: cover; */
  background-image: url("../../Assets/images/left-line.png");
  background-position: right;
}

.blog-card .card-wrapper .card-img {
  width: 50%;
}

.blog-card .card-wrapper .card-img img {
  max-width: 100%;
}

.blog-card .card-wrapper .card-details {
  width: 50%;
}

.blog-card .card-wrapper .card-details h2 {
  font-family: "Unbounded", sans-serif;
  font-size: 60px;
  font-weight: 600;
  line-height: 74.4px;
  text-align: left;
  margin-bottom: 40px;
  color: #ffffff;
}

.blog-card .card-wrapper .card-details p {
  font-family: AileronRegular;
  font-size: 30px;
  font-weight: 400;
  line-height: 36px;
  text-align: left;
  color: #686a67;
  margin: 0;
}

@media (max-width: 1920px) and (min-width: 991px) {
  .blog-card .card-wrapper.left-text::after {
    left: -50px;
  }

  .blog-card .card-wrapper.right-text::after {
    right: -50px;
  }
}

@media (max-width: 1920px) {
  .blog-card .card-wrapper .card-details h2 {
    font-size: 48px;
    margin-top: 0;
    margin-bottom: 28px;
    line-height: 54px;
  }

  .blog-card .card-wrapper .card-details p {
    font-size: 24px;
    line-height: 28px;
    max-width: 500px;
  }
}

@media (max-width: 1199px) {
  .blog-card .card-wrapper .card-details p {
    font-size: 24px;
    line-height: 32px;
  }
}

@media (max-width: 991px) {
  .blog-card .card-wrapper .card-details h2 {
    font-size: 36px;
    line-height: 42px;
    margin: 0 0 20px 0;
  }

  .blog-card .card-wrapper .card-details p {
    font-size: 18px;
    line-height: 24px;
  }

  .blog-card .card-wrapper.left-text::after {
    width: 100%;
    background-size: 100% 100%;
    left: -15px;
  }

  .blog-card .card-wrapper.right-text::after {
    width: 100%;
    background-size: 100% 100%;
    right: -15px;
  }
}

@media (max-width: 768px) {
  .blog-card .card-wrapper .card-details h2 {
    font-size: 22px;
    line-height: 24px;
    margin: 0 0 12px 0;
  }

  .blog-card .card-wrapper .card-details p {
    font-size: 16px;
    line-height: 18px;
  }

  .blog-card .card-wrapper {
    gap: 16px;
  }
}

@media (max-width: 425px) {
  .blog-card .card-wrapper {
    gap: 12px;
  }

  .blog-card .card-wrapper .card-details h2 {
    line-height: 22px;
    font-size: 18px;
    margin: 0 0 6px 0;
  }

  .blog-card .card-wrapper .card-details p {
    font-size: 12px;
    line-height: 14px;
  }
}

@media (max-width: 375px) {
  .blog-card .card-wrapper .card-details h2 {
    line-height: 18px;
    font-size: 16px;
  }

  .blog-card .card-wrapper .card-details p {
    font-size: 10px;
    line-height: 12px;
  }
}