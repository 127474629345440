.scan-download-modal {
  height: 100%;
  left: 0;
  outline: 0;
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1055;
  background-color: rgba(0, 0, 0, 0.6);
}
.modal-dialog {
  margin: 1.75rem auto;
  max-width: 954px;
  transition: transform 0.3s ease-out;
  transform: translateY(-50px);
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - (1.75rem * 2));
}
.scan-download-modal.hide {
  display: none;
}
.scan-download-modal.show .modal-dialog {
  transform: none;
}
.modal-dialog .modal-content {
  border: 0.8px solid #f5f5f533;
  background: #141414;
  width: 100%;
  border-radius: 16px;
  padding: 56px;
  text-align: center;
  position: relative;
}

.modal-dialog .modal-content .close-btn {
  background: linear-gradient(180deg, #95f500 0%, #578f00 100%);
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 0;
  position: absolute;
  right: -30px;
  top: -30px;
}

.modal-dialog .modal-content .modal-title {
  font-family: "Unbounded", sans-serif;
  font-size: 47.85px;
  font-weight: 600;
  line-height: 59.34px;
  color: #95f500;
  margin: 0 0 42px 0;
}

.modal-dialog .modal-content .store-wrap {
  display: flex;
  gap: 44px;
  align-items: center;
  margin-top: 44px;
}

.modal-dialog .modal-content .store-wrap > a {
  display: flex;
  justify-content: space-between;
  background: #f5f5f514;
  border: 1px solid #f5f5f533;
  width: 50%;
  border-radius: 20px;
  padding: 20px 27px;
  color: #95f500;
}

.modal-dialog .modal-content .store-wrap > a span {
  max-width: 240px;
  font-family: AileronSemiBold;
  font-size: 24px;
  font-weight: 600;
  line-height: 28.8px;
  text-align: center;
}

@media (max-width: 1440px) {
  .modal-dialog {
    max-width: 800px;
  }
  .modal-dialog .modal-content {
    padding: 40px;
  }
  .modal-dialog .modal-content .close-btn {
    width: 50px;
    height: 50px;
    right: -20px;
    top: -20px;
  }

  .modal-dialog .modal-content .close-btn svg {
    width: 24px;
    height: 24px;
  }
  .modal-dialog .modal-content .modal-title {
    font-size: 36px;
    line-height: 48px;
    margin: 0 0 28px 0;
  }
  .modal-dialog .modal-content .scan-card img {
    max-width: 200px;
  }

  .modal-dialog .modal-content .store-wrap > a span {
    font-size: 20px;
  }
}
@media (max-width: 991px) {
  .modal-dialog {
    max-width: 700px;
  }
  .modal-dialog .modal-content {
    padding: 28px;
  }
  .modal-dialog .modal-content .store-wrap {
    gap: 20px;
  }
  .modal-dialog .modal-content .store-wrap > a span {
    font-size: 16px;
    max-width: 160px;
  }
}

@media (max-width: 768px) {
  .modal-dialog {
    margin: 1.75rem 15px;
    max-width: 100%;
  }
  .modal-dialog .modal-content .close-btn {
    right: -10px;
    top: -10px;
  }
  .modal-dialog .modal-content .modal-title {
    font-size: 28px;
    line-height: 36px;
    margin: 0 0 20px 0;
  }
  .modal-dialog .modal-content .store-wrap {
    flex-flow: column;
    margin-top: 20px;
  }
  .modal-dialog .modal-content .store-wrap > a {
    padding: 12px 20px;
    width: 100%;
    max-width: 275px;
  }
}

@media (max-width: 575px) {
  .modal-dialog .modal-content .close-btn {
    width: 40px;
    height: 40px;
  }
  .modal-dialog .modal-content .modal-title {
    font-size: 20px;
    line-height: 24px;
  }
  .modal-dialog .modal-content .close-btn svg {
    width: 20px;
    height: 20px;
  }
  .modal-dialog .modal-content .scan-card img {
    max-width: 150px;
  }
  .modal-dialog .modal-content {
    padding: 20px;
  }
  .modal-dialog .modal-content .store-wrap > a {
    width: 80%;
    border-radius: 10px;
    align-items: center;
    max-width: 200px;
  }
  .modal-dialog .modal-content .store-wrap > a img {
    width: 30px;
  }
  .modal-dialog .modal-content .store-wrap > a span {
    font-size: 14px;
    line-height: 18px;
  }
}
