.footer-sec {
  background: #070d04;
  border: 1px solid #95f50033;
}

.footer-sec .footer-details {
  padding: 100px 0 50px 0;
  display: flex;
  gap: 88px;
  justify-content: center;
}

.footer-sec .footer-details > * {
  display: flex;
  align-items: center;
  gap: 34px;
}

.footer-sec .footer-details p {
  font-family: AileronBold;
  font-size: 28px;
  font-weight: 600;
  line-height: 33.6px;
  text-align: left;
  color: #7d7d7d;
  margin: 0;
}

.footer-sec .copyright-text {
  padding: 38px 15px;
  border-top: 1px solid #ffffff80;
}

.footer-sec .copyright-text p {
  margin: 0;
  text-align: center;
  font-family: AileronRegular;
  font-size: 24px;
  font-weight: 400;
  line-height: 28.8px;
  color: #7d7d7d;
}

@media (max-width: 1920px) {
  .footer-sec .footer-details p {
    font-size: 24px;
  }
  .footer-sec .copyright-text p {
    font-size: 22px;
  }
}

@media (max-width: 991px) {
  .footer-sec .footer-details {
    padding: 40px 0 20px 0;
    gap: 56px;
  }
  .footer-sec .footer-details p {
    font-size: 20px;
  }
  .footer-sec .footer-details > * {
    gap: 20px;
  }
  .footer-sec .footer-details a img {
    width: 32px;
  }
  .footer-sec .copyright-text {
    padding: 20px 15px;
  }
  .footer-sec .copyright-text p {
    font-size: 16px;
  }
}
@media (max-width: 768px) {
  .footer-sec .footer-details {
    padding: 30px 0 20px 0;
    gap: 40px;
  }
  .footer-sec .footer-details p {
    font-size: 16px;
  }
  .footer-sec .footer-details > * {
    gap: 16px;
  }
  .footer-sec .footer-details a img {
    width: 24px;
  }
  .footer-sec .copyright-text {
    padding: 15px;
  }
  .footer-sec .copyright-text p {
    font-size: 14px;
    line-height: 18px;
  }
}
@media (max-width: 425px) {
  .footer-sec .footer-details {
    flex-flow: column;
    gap: 16px;
  }
  .footer-sec .footer-details > * {
    justify-content: center;
  }
}
