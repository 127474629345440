@import url("https://fonts.googleapis.com/css2?family=Unbounded:wght@200..900&display=swap");

@font-face {
  font-family: AileronBlack;
  src: url("./Assets/Fonts/aileron/Aileron-Black.otf");
}

@font-face {
  font-family: AileronBold;
  src: url("./Assets/Fonts/aileron/Aileron-Bold.otf");
}

@font-face {
  font-family: AileronSemiBold;
  src: url("./Assets/Fonts/aileron/Aileron-SemiBold.otf");
}

@font-face {
  font-family: AileronLight;
  src: url("./Assets/Fonts/aileron/Aileron-Light.otf");
}

@font-face {
  font-family: AileronRegular;
  src: url("./Assets/Fonts/aileron/Aileron-Regular.otf");
}

body {
  margin: 0;
  font-family: AileronRegular;
  background: #000000;
  color: #ffffff;
}

code {
  font-family: "Unbounded", sans-serif;
}
