.about-sec {
  background-image: url('../../Assets/images/about-bg.png');
  background-size: 100% 100%;
  overflow: hidden;
}

.about-sec .about-wrap {
  padding: 236px 0;
  display: flex;
  align-items: center;
  gap: 90px;
}

.about-sec .about-wrap .about-details {
  width: 45%;
}

.about-sec .about-wrap .about-details h3 {
  font-family: "Unbounded", sans-serif;
  font-size: 66px;
  font-weight: 500;
  line-height: 81.84px;
  text-align: left;
  margin: 0 0 16px 0;
}

.about-sec .about-wrap .about-details h2 {
  font-family: "Unbounded", sans-serif;
  font-size: 72px;
  font-weight: 700;
  line-height: 89.28px;
  text-align: left;
  margin: 0 0 70px 0;
  color: transparent;
  background: linear-gradient(90deg, #49de17 0%, #ffffff 100%);
  -webkit-background-clip: text;
  background-clip: text;
}

.about-sec .about-wrap .about-details p {
  font-family: AileronRegular;
  font-size: 30px;
  font-weight: 400;
  line-height: 36px;
  text-align: left;
  margin: 0;
}

.about-sec .about-wrap .about-screen {
  width: 55%;
}

.about-sec .about-wrap .about-screen img {
  max-width: 100%;
}

@media (max-width: 1920px) {
  .about-sec .about-wrap {
    padding: 200px 0;
  }

  .about-sec .about-wrap .about-details h3 {
    font-size: 48px;
    line-height: 56px;
    margin: 0 0 16px 0;
  }

  .about-sec .about-wrap .about-details h2 {
    font-size: 56px;
    line-height: 64px;
    margin: 0 0 50px 0;
  }

  .about-sec .about-wrap .about-details p {
    font-size: 24px;
    line-height: 30px;
  }
}

@media (max-width: 991px) {
  .about-sec .about-wrap {
    padding: 156px 0;
    gap: 60px;
  }

  .about-sec .about-wrap .about-details h3 {
    font-size: 32px;
    line-height: 36px;
    margin: 0 0 8px 0;
  }

  .about-sec .about-wrap .about-details h2 {
    font-size: 42px;
    line-height: 56px;
    margin: 0 0 36px 0;
  }

  .about-sec .about-wrap .about-details p {
    font-size: 16px;
    line-height: 20px;
  }
}

@media (max-width: 768px) {
  .about-sec .about-wrap {
    flex-flow: column;
    gap: 90px;
    padding: 80px 0;
  }

  .about-sec .about-wrap .about-details {
    width: 100%;
  }

  .about-sec .about-wrap .about-screen {
    width: 100%;
  }
}

@media (max-width: 425px) {
  .about-sec .about-wrap {
    flex-flow: column;
    gap: 40px;
    padding: 40px 0;
  }

  .about-sec .about-wrap .about-details h3 {
    font-size: 24px;
    line-height: 30px;
    margin: 0 0 8px 0;
  }

  .about-sec .about-wrap .about-details h2 {
    font-size: 32px;
    line-height: 40px;
    margin: 0 0 20px 0;
  }

  .about-sec .about-wrap .about-details p {
    font-size: 14px;
    line-height: 18px;
  }
}