.scan-download-sec {
  margin-top: 120px;
  padding: 120px 0;
  border-top: 1px solid;
  border-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.14) 53%,
      rgba(255, 255, 255, 0) 100%
    )
    1;
}

.scan-download-bg {
  background-image: url("../../Assets/images/scan-bg.png");
  background-size: 100% 100%;
  padding: 90px 15px;
  text-align: center;
  overflow: hidden;
  position: relative;
}

.scan-download-bg .triangle-shape {
  position: absolute;
  left: 0;
  animation: spin 4s linear infinite;
}

.scan-download-bg .ring-shape {
  position: absolute;
  left: 0;
  bottom: 0;
  animation: spin 4s linear infinite;
}

.scan-download-bg .round-shape {
  position: absolute;
  right: 0;
  top: 0;
  animation: spin 4s linear infinite;
}

.scan-download-bg .ring-shape img,
.scan-download-bg .round-shape img,
.scan-download-bg .triangle-shape img {
  max-width: 100%;
}

.scan-download-bg h2 {
  font-family: "Unbounded", sans-serif;
  font-size: 50px;
  font-weight: 400;
  line-height: 60px;
  text-align: center;
  max-width: 1151px;
  margin: 0 auto 94px;
}

.scan-download-bg h2 span {
  font-weight: 700;
  color: #95f500;
}

.scan-download-bg button {
  border: 0;
  background-color: #95f500;
  padding: 28px 32px;
  display: flex;
  gap: 22px;
  align-items: center;
  margin: auto;
  border-radius: 12px;
  cursor: pointer;
}

.scan-download-bg button span {
  color: #000000;
  font-family: AileronBold;
  font-size: 26px;
  font-weight: 700;
  line-height: 31.2px;
  text-align: center;
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media (max-width: 1920px) {
  .scan-download-bg h2 {
    font-size: 34px;
    line-height: 48px;
    max-width: 750px;
    margin: 0 auto 60px;
  }
}

@media (max-width: 991px) {
  .scan-download-sec {
    margin-top: 60px;
    padding: 60px 0;
  }

  .scan-download-bg {
    padding: 60px 15px;
  }

  .scan-download-bg .triangle-shape img {
    max-width: 150px;
  }

  .scan-download-bg .ring-shape {
    max-width: 120px;
  }

  .scan-download-bg .round-shape {
    max-width: 150px;
  }

  .scan-download-bg h2 {
    font-size: 34px;
    line-height: 48px;
    max-width: 750px;
    margin: 0 auto 60px;
  }

  .scan-download-bg button {
    padding: 16px 24px;
  }

  .scan-download-bg button span {
    font-size: 20px;
    line-height: 24px;
  }

  .scan-download-bg .triangle-shape {
    top: 20px;
  }
}
@media (max-width: 768px) {
  .scan-download-bg h2 {
    font-size: 28px;
    line-height: 36px;
    max-width: 620px;
    margin: 0 auto 40px;
  }

  .scan-download-bg button {
    gap: 16px;
    padding: 12px 20px;
  }

  .scan-download-bg button span {
    font-size: 16px;
    line-height: 20px;
  }
}
@media (max-width: 575px) {
  .scan-download-bg h2 {
    font-size: 16px;
    line-height: 24px;
    max-width: 365px;
    margin: 0 auto 28px;
  }

  .scan-download-bg button img {
    width: 40px;
  }
}
@media (max-width: 425px) {
  .scan-download-sec {
    margin-top: 40px;
    padding: 40px 0;
  }

  .scan-download-bg {
    padding: 40px 15px;
  }

  .scan-download-bg .triangle-shape img {
    max-width: 100px;
  }

  .scan-download-bg .ring-shape {
    max-width: 90px;
  }

  .scan-download-bg .round-shape {
    max-width: 110px;
  }

  .scan-download-bg button span {
    font-size: 14px;
    line-height: 16px;
  }
}
