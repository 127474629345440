.App {
  text-align: center;
}
.container {
  max-width: 1720px;
  margin: auto;
  padding: 0 15px;
}
@media (max-width: 1920px) and (min-width: 991px) {
  .container {
    padding: 0 50px;
  }
}