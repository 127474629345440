.hero-sec {
  padding: 100px 0;
}

.animated-text {
  text-align: center;
  font-size: 80px;
  font-weight: 400;
  font-family: "Unbounded", sans-serif;
  animation: colorChange 6s infinite;
  margin: 0 0 80px 0;
  transition: 0.4s;
}

.animated-text b {
  font-weight: 700;
  font-family: "Unbounded", sans-serif;
}

@keyframes colorChange {
  0% {
    color: #95f500;
  }

  33% {
    color: #ffffff;
  }

  66% {
    color: transparent;
    background: linear-gradient(90deg, #49de17 0%, #ffffff 100%);
    -webkit-background-clip: text;
    background-clip: text;
  }

  100% {
    color: #95f500;
  }
}

.slider-container {
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  position: relative;
}

.slider-container .slider {
  display: flex;
  gap: 40px;
  transition: transform 1s linear;
  /* animation: slide 10s linear infinite; */
}

.slider-container .slider .slide {
  flex: 0 0 22%;
  width: 22%;
  min-width: 22%;
  display: inline-block;
  text-align: center;
  background: #f5f5f514;
  border: 1px solid #f5f5f533;
  padding: 30px;
  box-sizing: border-box;
  border-radius: 20px;
  height: fit-content;
}

.slider-container .slider .slide:first-child {
  margin-left: 10px;
}

.slider-container .slider .slide:nth-child(even) {
  margin-top: 250px;
}

.slider-container .slider .slide .img-sec {
  width: 100%;
  margin-bottom: 24px;
}

.slider-container .slider .slide .img-sec img {
  width: 100%;
}

.slider-container .slider .slide h4 {
  margin: 0;
  font-size: 30px;
  font-weight: 600;
  line-height: 36px;
  text-align: center;
}

@keyframes slide {
  0% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
  }
}

@media (max-width: 1920px) {
  .animated-text {
    font-size: 58px;
  }

  .slider-container .slider .slide h4 {
    font-size: 20px;
    line-height: 24px;
  }
}

@media (max-width: 1440px) {
  .slider-container .slider {
    gap: 28px;
  }

  .slider-container .slider .slide {
    padding: 16px;
  }

  .slider-container .slider .slide .img-sec {
    margin-bottom: 8px;
  }

  .slider-container .slider .slide:nth-child(even) {
    margin-top: 200px;
  }
}

@media (max-width: 991px) {
  .hero-sec {
    padding: 60px 0;
  }

  .slider-container .slider {
    gap: 16px;
  }

  .slider-container .slider .slide {
    padding: 12px;
    border-radius: 16px;
  }

  .slider-container .slider .slide .img-sec {
    margin-bottom: 6px;
  }

  .slider-container .slider .slide h4 {
    font-size: 12px;
    line-height: 16px;
  }

  .animated-text {
    font-size: 36px;
    line-height: 48px;
  }

  .slider-container .slider .slide:nth-child(even) {
    margin-top: 120px;
  }
}

@media (max-width: 768px) {
  .animated-text {
    font-size: 28px;
  }

  .slider-container .slider .slide:nth-child(even) {
    margin-top: 100px;
  }
}

@media (max-width: 575px) {
  .animated-text {
    font-size: 24px;
    margin: 0 0 40px 0;
  }

  .slider-container .slider {
    gap: 8px;
  }

  .slider-container .slider .slide:nth-child(even) {
    margin-top: 80px;
  }

  .slider-container .slider .slide h4 {
    white-space: wrap;
    font-size: 10px;
  }

  .slider-container .slider .slide {
    padding: 8px;
    border-radius: 12px;
  }

  .slider-container .slider .slide .img-sec {
    margin-bottom: 2px;
  }
}

@media (max-width: 425px) {
  .hero-sec {
    padding: 40px 0;
  }

  .animated-text {
    font-size: 18px;
    line-height: 24px;
    margin: 0 0 40px 0;
  }

  .slider-container .slider .slide {
    padding: 6px;
    border-radius: 6px;
  }

  .slider-container .slider .slide h4 {
    font-size: 8px;
    line-height: 8px;
  }

  .slider-container .slider .slide:nth-child(even) {
    margin-top: 60px;
  }
}