.benefits-sec {
  position: relative;
}

.benefit-shape {
  position: absolute;
  right: 0;
  bottom: 0;
}

.benefit-shape img {
  max-width: 100%;
  max-height: 120px;
}

.benefits-sec .benefits-wrap {
  display: flex;
  gap: 30px;
  text-align: center;
  justify-content: space-between;
  padding-bottom: 120px;
}

.benefits-sec .benefits-wrap .benefits-card {
  border: 1px solid #95f5001a;
  background: #95f5000f;
  padding: 30px;
  border-radius: 16px;
  width: 20%;
}

.benefits-sec .benefits-wrap .benefits-card .benefits-icon {
  background: #94f50014;
  padding: 12px;
  border-radius: 10px;
  width: 64px;
  height: 64px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.benefits-sec .benefits-wrap .benefits-card .benefits-title {
  margin: 20px 0 0 0;
  font-family: AileronRegular;
  font-size: 18px;
  font-weight: 400;
  line-height: 21.6px;
  text-align: center;
}

@media (max-width: 1920px) {
  .benefits-sec .benefits-wrap .benefits-card .benefits-title {
    margin: 16px 0 0 0;
    font-size: 16px;
    line-height: 18px;
  }
}

@media (max-width: 1440px) {
  .benefits-sec .benefits-wrap {
    gap: 16px;
  }

  .benefits-sec .benefits-wrap .benefits-card {
    padding: 16px;
  }
}

@media (max-width: 991px) {
  .benefits-sec .benefits-wrap .benefits-card {
    padding: 12px;
  }

  .benefits-sec .benefits-wrap .benefits-card .benefits-title {
    margin: 12px 0 0 0;
    font-size: 12px;
    line-height: 14px;
  }

  .benefits-sec .benefits-wrap .benefits-card .benefits-icon {
    padding: 8px;
    border-radius: 8px;
    width: 48px;
    height: 48px;
  }
}

@media (max-width: 768px) {
  .benefits-sec .benefits-wrap {
    flex-flow: wrap;
    justify-content: start;
    padding-bottom: 100px;
  }

  .benefits-sec .benefits-wrap .benefits-card {
    width: calc(33.33% - 38px);
  }

  .benefit-shape img {
    max-height: 100px;
  }
}

@media (max-width: 425px) {
  .benefits-sec .benefits-wrap {
    flex-flow: wrap;
    justify-content: start;
  }

  .benefits-sec .benefits-wrap .benefits-card {
    width: calc(50% - 34px);
  }
}